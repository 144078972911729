import GenericLayout from "@/features/layout/components/GenericLayout"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { useRouter } from "next/router"
import Link from "@/components/Link"
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded"
import Head from "next/head"
import RepeatIcon from "@mui/icons-material/Cached"

const StatusPage = ({ header, h1, h2, body, cta, icon, onClick }) => {
  return (
    <>
      <Head>{header}</Head>
      <GenericLayout is404>
        <Box sx={{ textAlign: "center", marginTop: "4rem" }}>
          <Typography variant="h1" sx={{ fontSize: "7rem" }}>
            {h1}
          </Typography>
          <Typography variant="h2" sx={{ fontSize: "2rem" }}>
            {h2}
          </Typography>
          <Typography variant="body1" sx={{ marginY: "2rem" }}>
            {body}
          </Typography>
          <Link
            onClick={onClick}
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {icon}
            {cta}
          </Link>
        </Box>
      </GenericLayout>
    </>
  )
}

export const FourOhFour = () => {
  const router = useRouter()

  return (
    <StatusPage
      header="404 - Page Not Found - Finli"
      h1="404!"
      h2="Page not found."
      body="We're sorry but the page you're looking for doesn't exist."
      cta="Click Here to Go Back"
      icon={<ChevronLeftRounded fontSize="small" />}
      onClick={() => router.back()}
    />
  )
}

export const Oops = () => {
  const router = useRouter()

  return (
    <StatusPage
      header="Oops! Something went wrong - Finli"
      h1="Oops!"
      h2="Something went wrong."
      body="Please reload the page to try again."
      cta="Refresh"
      icon={<RepeatIcon fontSize="small" sx={{ position: "relative", bottom: "1px", marginRight: "2px" }} />}
      onClick={() => router.reload()}
    />
  )
}

export default StatusPage
